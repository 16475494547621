<template>
  <div class="info-card">
    <MyLink
      class="info-card__image"
      :name="pageName"
      :params="{
        [slugName]: preview.fields.url.ru,
      }"
    >
      <img
        v-if="preview.fields.img"
        :src="useNormalizeCtfUrl(preview.fields.img.ru.fields.file.ru.url)"
        :alt="preview.fields.img.ru.fields.title.ru"
        loading="lazy"
      />
      <img v-else src="/img/photo-plug.svg" alt="banner" loading="lazy" />
    </MyLink>
    <div class="info-card__content">
      <div class="info-card__header">
        <BlogLabel :label="preview.fields.category.ru" />
        <time
          v-if="preview.fields.created"
          :datetime="new Date(preview.fields.created.ru).toISOString()"
          class="info-card__data"
        >
          {{ getDate(preview.fields.created.ru) }}
        </time>
      </div>
      <div class="info-card__content-wrp">
        <MyLink
          class="info-card__description"
          :name="pageName"
          :params="{
            [slugName]: preview.fields.url.ru,
          }"
        >
          {{ getLocalizedName(preview.fields.name) }}
        </MyLink>
        <MyLink
          class="info-card__go-to"
          :name="pageName"
          :params="{
            [slugName]: preview.fields.url.ru,
          }"
        >
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7757 1L17 7M17 7L11.7757 13M17 7H1" stroke="#602C88" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </MyLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import BlogLabel from "~/modules/blog/components/BlogLabel.vue";
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

const props = defineProps({
  preview: { type: Object, required: true },
  pageName: { type: String, required: true },
  slugName: { type: String, required: true },
});

const getDate = (date) =>
  useState("blog card date" + date, () =>
    new Intl.DateTimeFormat("uk-UA", {}).format(Date.parse(date)),
  );
</script>

<style lang="scss" scoped>
  .info-card {
    @extend %shadow;
    background-color: white;

    border-radius: 8px;

    overflow: hidden;

    &__image {
      height: 100%;

      @include flex-container(column, center);
      flex: 1 0 242px;

      & > img {
        display: flex;
        object-fit: cover;
        height: inherit;
      }
    }

    &__content {
      height: 100%;

      @include flex-container(column, flex-start, center);

      padding: 16px 8px;
      gap: 16px;

      @include bigMobile {
        gap: 32px;
      }
    }

    &__header {
      width: 100%;

      @include flex-container(row, space-between, center);

      gap: 8px;
    }

    &__data {
      @include font(16, 22);
      letter-spacing: 0.02em;
      color: #393d38;
    }

    &__content-wrp {
      width: 100%;
      
      @include flex-container(row, space-between);
      flex: 1 1 auto;

      gap: 8px;
    }

    &__description {
      @include font(20, 30, 600);
      color: var(--color-grey-900);

      @include bigMobile {
        @include font(16, 24, 600);
      }
    }

    &__go-to {
      flex: 0 0 40px;
      height: 30px;

      @include flex-container(column, center, center);
      align-self: flex-end;

      border: 1px solid var(--color-primary-base);
      border-radius: 8px;
    }
  }
</style>
